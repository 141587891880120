<template>
  <vx-card actionable :class="{'back-scroll':createBroadCastPopup}">
    <vs-row>
      <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <h4>Whatsapp Broadcast List</h4>
      </vs-col>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        <v-select v-if="check_role" placeholder="Latest Broadcast" style="z-index: 1000; margin-top: 4%; margin: 10px"
          class="w-full" v-model="selected_broadacast" :options="latest_broadcast" label="name"></v-select>
      </vs-col>
      <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <vs-button  v-if="check_role" style="font-size: 14px; margin: 5px" size="small" color="success" type="gradient"
          @click="approveAll">Approve
        </vs-button>
      </vs-col>
      <vs-col vs-w="5"
        v-if="getrole('cm4') || getrole('wh2') || getrole('entry') || (getTeam('Acads') && getrole('ac1'))"
        vs-type="flex" vs-justify="flex-start" vs-align="center">
        <vs-button style="float: right; font-size: 14px; margin: 2px" size="small" color="success" type="gradient"
          icon="chat_bubble" @click="createBroadCast">Whatsapp &nbsp; &nbsp; <b style="font-size: 20px">+</b>
        </vs-button>
       
        <vs-button style="float: right; font-size: 14px; margin: 2px" size="small" color="success" type="gradient"
          icon="missed_video_call" @click="createwebinarBroadCast">Webinar&nbsp; &nbsp; <b style="font-size: 20px">+</b>
        </vs-button>
        <div  v-if="(getrole('entry') || 
        getrole('cm4') && getTeam('Admin')) || 
        getrole('wh2') || 
        getrole('cm1') || 
        getrole('exed2') ||
        (getTeam('Acads') && getrole('ac1'))
        ">
         <vs-button style="margin: 2px; font-size: 14px" size="small" color='primary' :disabled="!selected_table.length" type="filled" @click="getApprove">Approve</vs-button>
        </div>
        <vs-button style="margin: 2px; font-size: 14px" size="small" color='primary' :disabled="!selected_table.length" type="filled" @click="updateReject">Reject</vs-button>
      </vs-col>
    </vs-row><br />
    <vs-table multiple v-model="selected_table" :data="broadcasts" max-items="10">
      <template slot="thead">
        <vs-th>Create By</vs-th>
        <vs-th>City</vs-th>
        
        <vs-th>Courses</vs-th>
        <vs-th>Levels</vs-th>
        <vs-th>Focus</vs-th>
        <!-- <vs-th>Spoc IDs</vs-th> -->
        <vs-th>Spoc Names</vs-th>
        <vs-th>Total Count</vs-th>
        <vs-th>Completed Count</vs-th>
        <vs-th>Failed Count</vs-th>
        <vs-th>Created At</vs-th>
        <vs-th>Whatsapp Message</vs-th>
        <vs-th>Type</vs-th>
        <vs-th>Status</vs-th>
        <!-- <vs-th>Status</vs-th> -->
        <vs-th class="flex justify-center" v-if="getrole('entry') ||
          getrole('cm1') ||
          getrole('exed2') ||
          (getTeam('SR') && getSubTeam('SR') || getSubTeam('Alumni SR')) ||
          (getTeam('Acads') && getrole('ac1'))
        ">Operation</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.created_by_name }}</vs-td>
            <vs-td>{{ tr.city }}</vs-td>
           

            <vs-td>{{ tr.courses }}</vs-td>

            <vs-td>{{ tr.levels }}</vs-td>
            <vs-td>{{ tr.focus }}</vs-td>

            <!-- <vs-td>{{ tr.spoc_ids }}</vs-td> -->

            <vs-td>{{ tr.spoc_name }}</vs-td>

            <vs-td>{{ tr.total_count }}</vs-td>
            <vs-td>{{ tr.success_wab_items }}</vs-td>
            <vs-td>{{ tr.failed_wab_items }}</vs-td>

            <vs-td>
              <div>
                {{ getTime(tr.created_at) }}
              </div>
            </vs-td>
            <vs-td style="justify-content: center; display: flex">
              <a href="#" class="tooltip-right" @click="tooltipPopup(tr.whatsapp_message,tr.wab_type)"><span>
                  <vs-icon size="small" icon="textsms" color="success"></vs-icon>
                </span></a>
            </vs-td>
            <vs-td>
                {{ tr.wab_type }}
            </vs-td>
            <vs-td>
                {{wab_status[tr.approval_status]}}
            </vs-td>

            <vs-td v-if="
              getrole('entry') ||
              getrole('cm1') ||
              getrole('exed2') ||
              (getTeam('SR') && getSubTeam('SR') || getSubTeam('Alumni SR')) ||
              (getTeam('Acads') && getrole('ac1'))
            ">
              <div v-if="tr.approval_status === 'approved'" class="flex justify-center">
                <div class="broadcastControlButtons" :data-id="tr.id" data-type="pause" v-if="tr.status === 'running'"
                  @click="
                    changeBroadcastStatus(`pauseBroadcast/${tr.id}`, 'Pause')
                  " style="margin-left: 7%">
                  <img src="https://img.icons8.com/ios-glyphs/20/000000/pause.png" />
                </div>
                <div class="broadcastControlButtons" :data-id="tr.id" data-type="cancel" v-if="tr.status === 'running'"
                  @click="
                    changeBroadcastStatus(`cancelBroadcast/${tr.id}`, 'Cancel')
                  " style="margin-left: 7%">
                  <img src="https://img.icons8.com/fluent/20/000000/cancel-2.png" />
                </div>
                <div class="broadcastControlButtons" :data-id="tr.id" data-type="resume" v-if="tr.status === 'paused'"
                  @click="
                    changeBroadcastStatus(`resumeBroadcast/${tr.id}`, 'Start')
                  " style="margin-left: 7%">
                  <img v-if="
                    getrole('entry') ||
                    getrole('cm1') ||
                    getrole('exed2') ||
                    (getTeam('SR') && getSubTeam('SR')|| getSubTeam('Alumni SR')) ||
                    (getTeam('Acads') && getrole('ac1'))
                  " src="https://img.icons8.com/ios-glyphs/20/000000/play.png" />
                </div>
                <div v-if="tr.status === 'cancelled'">
                  <img src="https://img.icons8.com/fluent/20/000000/cancel-2.png" />
                </div>
                <div v-if="tr.status === 'completed'">
                  <img src="https://img.icons8.com/color/20/000000/ok.png" />
                </div>
              </div>
            </vs-td>
            <vs-td
              v-if="(getTeam('Acads') && getrole('ac1')) || (getrole('entry') || getrole('cm4') && getTeam('Admin')) || getrole('wh2') || getrole('cm1') || getrole('exed2')"
              style="justify-content: center;  display: flex;}">
              <div v-if="(getrole('cm4') && getTeam('Admin')) || getrole('wh2')">
                <div v-if="tr.approval_status === 'pending'" style="display: inline-flex">
                  <vs-button style="margin: 2px; font-size: small" @click="permissionenable(tr.id, 'approved')"
                    size="small" color="success" type="filled">Approve</vs-button>
                  <vs-button style="margin: 2px; font-size: small" @click="permissionenable(tr.id, 'rejected')"
                    size="small" color="danger" type="filled">Reject</vs-button>
                </div>
                <div v-else-if="tr.approval_status === 'rejected'" style="display: inline-flex; pointer-events: none">
                  <vs-button style="margin: 2px; font-size: small" size="small" color="danger" type="filled">Rejected
                  </vs-button>
                </div>
                <div v-else-if="tr.approval_status === 'approved'" style="display: inline-flex; pointer-events: none">
                  <vs-button style="margin: 2px; font-size: small" size="small" color="primary" type="filled">Approved
                  </vs-button>
                </div>
              </div>
              <div
                v-if="(getTeam('Acads') && getrole('ac1')) || getrole('entry') || getrole('cm1') || getrole('exed2')">
                <div v-if="tr.approval_status === 'pending'" style="display: inline-flex; pointer-events: none">
                  <vs-button style="margin: 2px; font-size: small" size="small" color="warning" type="filled">Pending
                  </vs-button>
                </div>
                <div v-else-if="tr.approval_status === 'rejected'" style="display: inline-flex; pointer-events: none">
                  <vs-button style="margin: 2px; font-size: small" size="small" color="danger" type="filled">Rejected
                  </vs-button>
                </div>
                <div v-else-if="tr.approval_status === 'approved'" style="display: inline-flex; pointer-events: none">
                  <vs-button style="margin: 2px; font-size: small" size="small" color="success" type="filled">Approved
                  </vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-row>
      <vs-col vs-justify="flex-end" vs-w="12">
        <vs-pagination :total="total_pages" v-model="current_page"></vs-pagination>
      </vs-col>
    </vs-row>
    <vs-popup fullscreen class="new-whatsapp-broadcast" :active.sync="createBroadCastPopup">
      <CreateWhatsappBroadcast v-if="createBroadCastPopup" @refresh-table="refreshTable"></CreateWhatsappBroadcast>
    </vs-popup>

    <vs-popup fullscreen class="holamundo" title="Create Webinar Broadcast" :active.sync="createWebinarBroadCastPopup">
      <CreateWebinarBroadcast v-if="createWebinarBroadCastPopup" @refresh-table="refreshTable"></CreateWebinarBroadcast>
    </vs-popup>

    <vs-popup class="holamundo" title="" fullscreen :active.sync="whatsappCountPopup">
      <div>
        <vs-table max-items="15" pagination :data="wabCountDataPopup">
          <template slot="thead">
            <vs-th> Can-ID </vs-th>
            <vs-th> Person Name </vs-th>
            <vs-th> Mobile </vs-th>
            <vs-th> Whatsapp Message Name </vs-th>
            <vs-th> Whatsapp Message Text </vs-th>
            <vs-th>
              {{ WabType }}
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].identity">
                {{ data[indextr].identity }}
              </vs-td>

              <vs-td :data="data[indextr].person_name">
                {{ data[indextr].person_name }}
              </vs-td>

              <vs-td :data="data[indextr].mobile">
                {{ data[indextr].mobile }}
              </vs-td>

              <vs-td :data="data[indextr].whats_app_message_name">
                {{ data[indextr].whats_app_message_name }}
              </vs-td>
              <vs-td :data="data[indextr].whats_app_message_text">
                {{ data[indextr].whats_app_message_text }}
              </vs-td>

              <vs-td :data="data[indextr].error">
                <p v-if="data[indextr].error !== null">
                  {{ data[indextr].error }}
                </p>
                <vs-icon v-if="data[indextr].error === null" color="success" icon="done"></vs-icon>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-popup>
    <vs-popup class="tooltip-popup-width" style="z-index: 53010" :active.sync="tooltipPopupOpen">
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <h5 class="popup-headd">{{ popup_title }}</h5>
        </vs-col>
        <vs-col vs-w="12" class="mb-2">
          <p>{{messages_popup_data1}} !</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <p class="popup-inner">{{ messages_popup_data }}</p>
        </vs-col>
        <vs-col vs-w="12" class="mt-2">
          <p>Cheers Team Miles Education.</p>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-slign="center" class="mt-4">
          <vs-button type="filled" size="small" @click="tooltipPopupOpen = false"><b>OK</b></vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </vx-card>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import moment from "moment";
import CreateWhatsappBroadcast from "../components/whatsapp/NewCreateWhatsappBroadcast.vue";
import CreateWebinarBroadcast from "../components/webinar/CreateWebinarBroadcast.vue";
import swal from "sweetalert2/src/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import vSelect from "vue-select";
export default {
  mounted() {
    this.fetchBroadcasts();
    if (
      localStorage.getItem("spoc_id") === "39" ||
      localStorage.getItem("spoc_id") === "132" ||
      localStorage.getItem("spoc_id") === "77" ||
      localStorage.getItem("spoc_id") === "432" ||
      localStorage.getItem("spoc_id") === "937"
    ) {
      this.getLatestBroadcast();
      this.check_role = true
    }

  },
  data() {
    return {
      wab_status:{1:"Approved",2:"Rejected",0:"Pending"},
      tooltipPopupOpen:false,
      messages_popup_data:"",
      messages_popup_data1:"",
      popup_title:"",
      check_role: false,
      selected_table: [],
      total_pages: 1,
      current_page: 1,
      broadcasts: [],
      createBroadCastPopup: false,
      createWebinarBroadCastPopup: false,
      whatsappCountPopup: false,
      wabCountDataPopup: [],
      WabType: "",
      latest_broadcast: [],
      selected_broadacast: "",
    };
  },
  components: {
    CreateWhatsappBroadcast,
    CreateWebinarBroadcast,
    "v-select": vSelect,
  },
  watch: {
    current_page() {
      this.fetchBroadcasts();
    },
    // selected_table(val){
    //   console.log("selected",val)
    // }
  },
  methods: {
    tooltipPopup(tr, title) {
      this.popup_title = title.charAt(0).toUpperCase() + title.slice(1);
      this.tooltipPopupOpen = true;
      let result = tr.replace(/[*,_]/g, " ");
      let index1 = result.indexOf("!");
      let index2 = result.indexOf("Cheers")
      this.messages_popup_data = result.slice(index1+1,index2)
      this.messages_popup_data1 = result.slice(0,index1)
    },
    getLatestBroadcast() {
      let url = `${constants.MILES_CM_BACK}getLatestWhatsAppBroadcasts`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("Latest WhatsappBroadcast", response);
          this.latest_broadcast = [];
          let data = response.data.webinar_messages;
          let broadcast = {};
          for (let i = 0; i < data.length; i++) {
            broadcast = {
              id: data[i].id,
              name: data[i].name + " - " + data[i].created_at,
            };
            this.latest_broadcast.push(broadcast);
          }
          console.log("LatestWhatsappBroadcast", this.latest_broadcast);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    approveAll() {
      console.log("approveAll", this.selected_broadacast);
      let url = `${constants.MILES_CM_BACK}approveBroadcastsPanIndia`;
      let obj = { whatsapp_message_id: this.selected_broadacast.id };
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("approve", response);
          if (response.data === "success") {
            this.$vs.notify({
              title: "Successfully Approved",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Please contact admin",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.notify({
            title: `Error : ${error}`,
            color: "danger",
          });
        });
    },
    refreshTable() {
      this.createBroadCastPopup = false;
      this.fetchBroadcasts();
    },
    fetchBroadcasts() {
      this.$vs.loading();
      let url =
        `${constants.MILES_WHATSAPP_BROADCASTS}getAllBroadcast?page=` + this.current_page;
      //  this.paramsObj.page = this.current_page;
      axios
        .get(url, {
          params: this.paramsObj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log('object--',response.data.data);
          // this.broadcasts = response.data.data;
          this.total_pages = response.data.last_page;
          this.current_page = response.data.current_page;
          this.broadcasts = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    updateReject(){
      let obj = {
        "ids": []
        }
      for (let i = 0; i < this.selected_table.length; i++) {
        const element = this.selected_table[i];
        obj.ids.push(element.id)
      }
      console.log("getApprove",obj)
      let url = `${constants.MILES_WHATSAPP_BROADCASTS}updateApprovalStatusAsRejected`;
      axios
        .put(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("approve", response);
          if (response.data === "success") {
            this.$vs.notify({
              title: "Successfully Rejected",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Please contact admin",
              color: "danger",
            });
          }
          this.selected_table = [];
          this.fetchBroadcasts();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.notify({
            title: `Error : ${error}`,
            color: "danger",
          });
        });
    },
    getApprove(){
      let obj = {
        "ids": []
        }
      for (let i = 0; i < this.selected_table.length; i++) {
        const element = this.selected_table[i];
        obj.ids.push(element.id)
      }
      console.log("getApprove",obj)
      let url = `${constants.MILES_WHATSAPP_BROADCASTS}updateApprovalStatus`;
      axios
        .put(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("approve", response);
          if (response.data === "success") {
            this.$vs.notify({
              title: "Successfully Approved",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Please contact admin",
              color: "danger",
            });
          }
          this.selected_table = [];
          this.fetchBroadcasts();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.notify({
            title: `Error : ${error}`,
            color: "danger",
          });
        });
    },
    getTime(date){
      return moment(date, "YYYY-MM-DD h:mm:ss").format("DD-MMM-YYYY h:mm A");
    },
    changeBroadcastStatus(endpoint, status) {
      swal
        .fire({
          title: "Are you sure?",
          text: `You want to ${status} this`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            let url = `${constants.MILES_CM_BACK}${endpoint}`;
            axios
              .post(
                url,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                if (response.data === "success") {
                  this.fetchBroadcasts();
                }
                // this.broadcasts = response.data;
              })
              .catch((error) => {
                this.handleError(error);
              });
          }
        });
      // console.log(status);
    },
    createBroadCast() {
      this.createBroadCastPopup = true;
    },
    createwebinarBroadCast() {
      this.createWebinarBroadCastPopup = true;
    },
    countPopup(id, type) {
      this.$vs.loading();
      let obj = {
        wab_id: id,
        type: type,
      };
      console.log("count popup data", obj);
      if (obj.type === "success") {
        this.WabType = "Success";
      } else {
        this.WabType = "Error";
      }

      let url = `${constants.MILES_CM_BACK}getBroadcastItemsForDisplay`;
      this.wabCountDataPopup = [];
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("wab popu data", response);
          this.wabCountDataPopup = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });

      this.whatsappCountPopup = true;
    },
    permissionenable(id, status) {
      let endpoint = "approveBroadcastRequest";
      let obj = {
        whatsapp_broadcast_id: id,
        approval_status: status,
      };
      let statusMsg = "";
      if (status === "approved") {
        statusMsg = "approve";
      } else {
        statusMsg = "reject";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: `You want to ${statusMsg} this `,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            let url = `${constants.MILES_CM_BACK}${endpoint}`;
            axios
              .post(url, obj, {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              })
              .then((response) => {
                console.log(response);
                this.fetchBroadcasts();
                this.$vs.notify({
                  title: "Successfully Updated",
                  text: "",
                  color: "success",
                });
              })
              .catch((error) => {
                this.handleError(error);
              });
          }
        });
    },
  },
};
</script>

<style>
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

.permission div.vs-table-text {
  justify-content: center;
}

i.vs-icon.notranslate.icon-scale.vs-button--icon.material-icons {
  font-size: 20px;
}
.new-whatsapp-broadcast>.vs-popup>.vs-popup--header{
  background-color: #fff;
}
.new-whatsapp-broadcast>.vs-popup>.vs-popup--header>.vs-popup--title{
  display: none;
}
.new-whatsapp-broadcast>.vs-popup>.vs-popup--header>.vs-icon{
margin-left: auto;
}
.new-whatsapp-broadcast>.vs-popup{
  border-radius: 15px;
}
.back-scroll{
  overflow-y: hidden !important;
  height: 0px !important;
}
</style>
