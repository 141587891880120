<template>
	<div>
		<vx-card actionable class="cardx" title>
			<vs-row>
				<vs-col vs-w="6">
					<h4>Webinar Broadcast</h4>
				</vs-col>

				<!-- <vs-col vs-w="6" v-if="showCount">
          <vs-button
            size="medium"
            color="primary"
            @click="broadcastWAData()"
            class="broadcast-control"
            id="broadcastbtn-visible"
            >Broadcast</vs-button
          >
        </vs-col> -->
			</vs-row>
			<br />
			<vs-row>
				<vs-col vs-w="6" class="whatsappbtnsection">
					<vs-card>
						<vs-row>
							<vs-col
								vs-w="12"
								vs-justify="center"
								vs-align="center"
								style="display: flex"
							>
								<vs-radio
									v-if="getrole('cm1') || getrole('cm4')"
									v-model="select_role"
									vs-value="CM"
									>CM</vs-radio
								>
							</vs-col>
						</vs-row>
						<vs-row class="mt-5">
							<vs-col
								vs-w="12"
								vs-justify="center"
								vs-align="center"
								style="display: flex"
							>
								<div v-if="select_role == 'CM'">
									<div class="flex flex-row gap-4">
										<vs-checkbox
											v-for="(course, index) in CM_courses_checkbox"
											:key="index"
											color="dark"
											class="mr-2"
											:vs-value="course"
											v-model="initSelectedCourses"
											>{{ course }}</vs-checkbox
										>
									</div>
								</div>
							</vs-col>
						</vs-row>

						<vs-row class="gap">
							<vs-col
								vs-type="flex"
								vs-justify="flex-end"
								vs-align="center"
								vs-w="5"
							>
								<p style="font-size: 14px; font-weight: bold">Daily Limit :</p>
							</vs-col>
							<vs-col vs-w="1"></vs-col>

							<vs-col
								vs-type="flex"
								vs-justify="flex-end"
								vs-align="center"
								vs-w="3"
							>
								<vs-input
									class="inputx"
									type="number"
									style="width: 250px"
									placeholder="Daily Limit"
									v-model="daily_limit"
								/>
							</vs-col>
						</vs-row>
						<vs-row>
							<vs-col
								vs-type="flex"
								vs-justify="flex-end"
								vs-align="center"
								vs-w="5"
							>
								<p style="font-size: 14px; font-weight: bold">From Date:</p>
							</vs-col>
							<vs-col vs-w="1"></vs-col>
							<vs-col
								vs-type="flex"
								vs-justify="flex-start"
								vs-align="center"
								vs-w="3"
							>
								<datepicker
									placeholder="Select Date"
									v-model="fromDate"
									:disabledDates="state.disabledDates"
								></datepicker>
							</vs-col>
						</vs-row>
						<vs-row class="gap">
							<vs-col
								vs-type="flex"
								vs-justify="flex-end"
								vs-align="center"
								vs-w="5"
							>
								<p style="font-size: 14px; font-weight: bold">Count :</p>
							</vs-col>
							<vs-col
								vs-type="flex"
								vs-justify="flex-end"
								vs-align="center"
								vs-w="1"
							>
								<div style="font-size: 14px; font-weight: bold">
									{{ this.WACount }}
								</div>
							</vs-col>
							<vs-col
								vs-type="flex"
								vs-justify="flex-end"
								vs-align="center"
								vs-w="3"
							>
								<!-- <vs-button
									style="margin: 5px; bottom: 0px; font-size: small"
									color="dark"
									size="small"
									class="gap"
									type="gradient"
									@click="getWABCount()"
									>Get Count</vs-button
								> -->
								<vs-button
									ref="loadableButton"
									style="margin: 5px; bottom: 0px; font-size: small"
									id="button-with-loading"
									class="vs-con-loading__container gap"
									@click="getWABCount()"
									type="gradient"
									color="dark"
									size="small"
									>Get Count</vs-button
								>
							</vs-col>
						</vs-row>
						<vs-row>
							<vs-col vs-w="12" style="justify-content: center; display: flex">
								<vs-button
									size="small"
									class="gap"
									color="dark"
									type="gradient"
									icon="refresh"
									style="margin: 5px; bottom: 0px; font-size: small"
									@click="refreshdata"
									>refresh</vs-button
								>
								<!-- <vs-button
									size="small"
									class="gap"
									color="dark"
									type="gradient"
									style="margin: 5px; bottom: 0px; font-size: small"
									@click="checkBroadcastStatus"
									>Create Broadcast</vs-button
								> -->
								<vs-button
									ref="create_broadcast"
									style="margin: 5px; bottom: 0px; font-size: small"
									id="button-with-loading"
									class="vs-con-loading__container gap"
									@click="checkBroadcastStatus"
									type="gradient"
									color="dark"
									size="small"
									>Create Broadcast</vs-button
								>
								<vs-chip
									style="margin: 5px; bottom: 0px; font-size: small"
									v-if="showCount"
									color="primary"
								>
									<b>{{ leadCount }}</b>
								</vs-chip>
							</vs-col>
						</vs-row>
					</vs-card>
				</vs-col>
				<vs-col vs-w="6">
					<vs-card>
						<vs-row>
							<vs-col>
								<vs-alert
									:active.sync="messageError"
									color="danger"
									closable
									close-icon="close"
									>Please Select Message</vs-alert
								>
							</vs-col>
						</vs-row>
						<!-- <vs-list>
							<vs-list-header title="Messages"></vs-list-header>

							<div>
								<vs-list-item
									v-for="whatsappMessage in whatsappMessages"
									:key="whatsappMessage.id"
									:title="whatsappMessage.name"
									:data-tooltip="whatsappMessage.message"
								>
									<vs-radio
										color="success"
										v-model="selectedWhatsappMessage"
										:vs-value="whatsappMessage.id"
									></vs-radio>
								</vs-list-item>
							</div>
						</vs-list> -->
						<vs-table max-items="8" search pagination :data="whatsappMessages">
							<template slot="header">
								<h5 style="position:relative;left:20px">Messages</h5>
							</template>
							<!-- <template slot="thead">
								<vs-th> Messages </vs-th>
							</template> -->

							<template slot-scope="{ data }">
								<vs-tr :key="indextr" v-for="(tr, indextr) in data">
									<vs-td :data="tr.id" :data-tooltip="tr.message">
										<div>
											<vs-radio
												color="success"
												v-model="selectedWhatsappMessage"
												:vs-value="tr.id"
											></vs-radio>
											<span>{{ tr.name }} </span>
										</div>
									</vs-td>
								</vs-tr>
							</template>
						</vs-table>
					</vs-card>
				</vs-col>
			</vs-row>
		</vx-card>
	</div>
</template>

<script>
// import _ from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import LeadsNavBar from "../pagesComponents/LeadsNavBar.vue";
import FocusSearch from "../pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import EventBus from "../eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import LoadingButton from "../utilities/LoadingButton";
import moment from "moment";
export default {
	components: {
		LoadingButton,
		Datepicker,
		LeadsNavBar,
		VTree,
		VSelectTree,
		DateRangePicker,
		FocusSearch,
		SpocSearchTree,
		Treeselect,
		"v-select": vSelect,
		VuePerfectScrollbar,
	},
	props: ["all_loading"],
	data() {
		return {
			state: {
				disabledDates: {
					from: new Date(),
				},
			},
			fromDate: "",
			WACount: "",
			loadShow: true,
			countShow: false,
			daily_limit: "500",
			select_role: "CM",
			messageError: false,
			showCount: false,
			leadCount: 0,
			whatsappMessages: [],
			selectedWhatsappMessage: "",

			initSelectedCourses: [],
			CM_courses_checkbox: ["CPA", "CMA", "CFA", "FRM", "USP", "CPA-AA","CPA-US","IIMI-BA","IIML-FT", "AICPA", "XLRI-HR","XLRI-SH","IIMI-AA"],

			// CM_courses_checkbox: ["CPA", "CMA", "CFA", "FRM", "USP", "CPA-AA", "CIA", "CISA", "EA"],
			count: 0,
			getSelectedCource: "",
		};
	},
	mounted() {
		this.getwhatsappMessages();
	},
	watch: {
		initSelectedCourses: function (val) {
			console.log("selected cource", val.toString());
			this.getSelectedCource = val.toString();
		},
	},
	methods: {
		getWABCount() {
			this.$vs.loading({
				background: "dark",
				color: "#ffffff",
				container: this.$refs.loadableButton.$el,
				scale: 0.45,
			});
			if (
				this.getSelectedCource !== "" ||
				this.getSelectedCource !== undefined ||
				this.getSelectedCource !== null
			) {
				let obj = {
					course: this.getSelectedCource,
					from_date: moment(this.fromDate).format("YYYY-MM-DD")

				};
				axios
					.get(`${constants.MILES_CM_BACK}getWebinarCountForBoradcast`, {
						params: obj,
						headers: {
							Authorization: `Bearer ${localStorage.userAccessToken}`,
						},
					})
					.then((response) => {
						console.log("WACount", response);
						this.WACount = response.data;
						this.$vs.loading.close(this.$refs.loadableButton.$el);
					})
					.catch((error) => {
						this.handleError(error);
					});
			} else {
				this.$vs.notify({
					title: "Course Field required",
					text: "Please Select Course",
					color: "danger",
				});
			}
		},
		checkBroadcastStatus() {
			let obj = this.getSelectedParamsForRequests();
			console.log(obj);
			if (
				obj.whats_app_message_id === "" ||
				obj.whats_app_message_id === undefined
			) {
				this.messageError = true;
				return;
			}
			console.log("c w b", obj);
			// return;
			this.$vs.loading({
				background: "dark",
				color: "#ffffff",
				container: this.$refs.create_broadcast.$el,
				scale: 0.45,
			});
			axios
				.post(`${constants.MILES_CM_BACK}createWebinarBoradcast`, obj, {
					headers: {
						Authorization: `Bearer ${localStorage.userAccessToken}`,
					},
				})
				.then((response) => {
					console.log(response);
					this.showCount = true;
					this.leadCount = response.data;
					this.$emit("refresh-table");
					this.$vs.loading.close(this.$refs.create_broadcast.$el);
				})
				.catch((error) => {
					this.handleError(error);
				});
		},

		getSelectedParamsForRequests() {
			let obj = {
				course: this.getSelectedCource,
				whats_app_message_id: this.selectedWhatsappMessage,
				page: 1,
				daily_limit: this.daily_limit,
				from_date: moment(this.fromDate).format("YYYY-MM-DD"),
			};

			// obj.my_focus = focus;
			return obj;
		},

		refreshdata() {
			EventBus.$emit("refresh-focus");
			this.initSelected = "";
			this.initSelectedCourses = [];
			this.loadShow = false;
			this.countShow = false;
			// this.dateRange.startDate = "";
			// this.dateRange.endDate = "";
		},

		getwhatsappMessages() {
			//http://milescmback.2x2.ninja/api/whatsAppBroadcast
			let url = `${constants.SERVER_API}WhatsappBroadcastMessage`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${localStorage.userAccessToken}`,
					},
				})
				.then((response) => {
					console.log("whatsapp messages", response.data);
					this.whatsappMessages = response.data;
				});
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.headr {
	color: gray;
	font-size: 25px;
	margin-bottom: 2%;
}
.gap {
	margin-bottom: 4%;
}
.inicolor {
	color: gray;
}
.cardtext {
	color: gray;
	font-size: 20px;
}
.tree3 {
	padding: 10px;
	box-sizing: border-box;
	border: 1px dotted #ccccdd;
	overflow: auto;
	height: 500px;
	width: 300px;
}
.vs-dropdown--menu {
	width: 300px;
}
.a-icon {
	outline: none;
	text-decoration: none !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
.gap {
	margin-right: 1%;
}
.examplex {
	display: flex;
	align-items: center;
	justify-content: center;
}
.a-icon {
	outline: none;
	text-decoration: none !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
i {
	font-size: 18px;
}
.vl {
	border-left: 1px solid black;
	height: 35px;
}
#style-1::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #f5f5f5;
}
.scrollbar {
	overflow-y: scroll;
}
.buttonrow {
	// justify-content: center;
	margin: 5px;
	justify-content: flex-end;
}
.buttonlist {
	width: 200px;
}
.con-vs-dropdown--menu {
	z-index: 500000;
}
</style>