<template>
  <div>
    <div v-if="classn == 'bttn-save'">
      <vs-button ref="loadableButton" :id="id" color="" @click="clicked" :disabled="disabled"
        :class="[classn, 'vs-con-loading__container']">
        <img src="../../assets/images/elements/floppy-disk.png" style="width:25px;height:25px">
      </vs-button>
    </div>
    <div v-if="typeoff">
      <vs-button ref="loadableButton" :id="id" color="" @click="clicked" :disabled="disabled"
        :class="[classn, 'vs-con-loading__container']"><img src="../../assets/images/elements/video-camera1.png"
          style="width:25px;height:25px"></vs-button>
    </div>
    <div v-if="classn == 'bttn-save2'">
      <vs-button ref="loadableButton" :id="id" color="" @click="clicked" :disabled="disabled" :class="[classn, 'vs-con-loading__container']">
        <img src="../../assets/images/elements/floppy-disk1.png" style="width:25px;height:25px;margin-right:15px">
        <img src="../../assets/images/elements/chat.png" style="width:25px;height:25px;margin-right:15px">
        <img src="../../assets/images/elements/envelope2.png" style="width:25px;height:25px">
      </vs-button>
    </div>
    <div v-if="classn == 'bttn-save3'">
          <vs-button ref="loadableButton" :id="id" color="" @click="clicked" :disabled="disabled" :class="[classn, 'vs-con-loading__container']">
            <img src="../../assets/images/elements/floppy-disk3.png" style="width:25px;height:25px;margin-right:15px">
            <img src="../../assets/images/elements/chat1.png" style="width:25px;height:25px;margin-right:15px">
            <img src="../../assets/images/elements/envelope.png" style="width:25px;height:25px;margin-right:15px">
            <img src="../../assets/images/elements/video-camera.png" style="width:25px;height:25px"></vs-button>
        </div>

    <div v-if="classn == 'usp-re-enrollment'">
      <vs-button ref="loadableButton" :id="id" color="primary" @click="clicked" :disabled="disabled"
        :class="[classn, 'vs-con-loading__container']">USP Re-Enrollment
      </vs-button>
    </div>
  </div>



  <!-- <div class="centerx">
    <vs-button
      ref="loadableButton"
      :id="id"
      class="vs-con-loading__container"
      @click="clicked"
      type="gradient"
      :color="color"
      :disabled="disabled"
    >
      <span v-if="type === 'text'">{{ name }}</span>
      <span v-if="type === 'icon'">
        <vs-icon
          v-for="(icon, index) in icons"
          :key="index"
          :icon="icon.name"
          size="small"
          :color="icon.color"
        ></vs-icon>
      </span>
    </vs-button>
  </div> -->
</template>

<script>
export default {
  props: ["name", "color", "loading", "type", "icons", "id", 'classn', 'typeoff'],
  data() {
    return {
      backgroundLoading: "primary",
      colorLoading: "#fff",
      disabled: false,
    };
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
  watch: {
    loading: function () {
      if (this.loading === true) {
        this.disabled = true;
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: `#${this.id}`,
          scale: 0.45,
        });
      } else {
        this.disabled = false;
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        this.$vs.loading.close(`#${this.id} > .con-vs-loading`);
      }
    },
  },
};
</script>

<style>
.bttn-save:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large){
  padding:5px !important;
}
.bttn-save2:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large){
  padding:5px !important;
}
.bttn-save3:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large){
  padding:5px !important;
}
.bttn-save {
  height: 34.57px;
  width: 40.37px;
  background: #FFEBD8;
}

.bttn-save2  {
  height: 34.57px;
  width: 128.7px;
  background: #d77fa140;
}

.bttn-save3  {
  height: 34.57px;
  width: 168.7px;
  background: #d8f4facc;
}

.bttn-save,
.bttn-save2,
.bttn-save3 {
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
}
</style>
