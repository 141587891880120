<template>
  <div>
    <vs-row>
      <vs-col vs-w="12">
        <vx-card actionable class="cardx card-inside-popup" title background>
          <vs-row>
            <vs-col vs-w="12" class="whatsapp-broadcast-header">
              <h4>Whatsapp Broadcast</h4>
            </vs-col>

            <!-- <vs-col vs-w="6" v-if="showCount"> <vs-button size="medium" color="primary"@click="broadcastWAData()"class="broadcast-control"id="broadcastbtn-visible">Broadcast</vs-button></vs-col> -->
          </vs-row>
          <br />
          <vs-row>
            <vs-col vs-w="7">
              <vs-card class="wab-card-height">
                <vs-row class="mt-4">
                  <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end" class="mb-2">
                    <vs-radio v-model="radios1" color="#0044ba" vs-name="radios1" vs-value="marketing" class="radios">
                      MARKERTING
                    </vs-radio>
                  </vs-col>
                  <vs-col vs-w="6" class="mb-2">
                    <vs-radio v-model="radios1" color="#0044ba" vs-name="radios1" vs-value="webinar" class="radios">
                      WEBINAR REMINDER
                    </vs-radio>
                  </vs-col>
                </vs-row>
                <div v-if="radios1 == 'marketing'">
                  <vs-row type="flex">
                    <!--Team -->
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                      <v-select v-model="selected_team" placeholder="Team" :options="teams"
                        style="z-index: 14000;width:168px;height:33.69px;" class="box-bordering">
                      </v-select>
                    </vs-col>
                    <!--City -->
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" v-if="selected_team != 'ExEd'">
                      <v-select v-model="selected_city" placeholder="City" :options="cities_list"
                        style="z-index: 14000;width:168px;height:33.69px;" class="box-bordering">
                      </v-select>
                    </vs-col>
                    <!--City classification -->
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" v-if="selected_team != 'ExEd'">
                      <v-select v-model="selected_city_classification" placeholder="City Classification"
                        :options="city_classification" style="z-index: 14000;width:168px;height:33.69px;"
                        class="box-bordering">
                      </v-select>
                    </vs-col>
                    <div style="margin-top:65px"></div>
                    <!--Tire -->
                    <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                      <v-select v-model="selected_tier" placeholder="Tier" :options="tier"
                        style="z-index: 14000;width:168px;height:33.69px;" class="box-bordering">
                      </v-select>
                    </vs-col> -->
                    <!--Course -->
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" class="tree-drop">
                      <vs-dropdown vs-custom-content vs-trigger-click>
                        <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                          Courses
                          <i class="material-icons">expand_more</i>
                        </a>
                        <vs-dropdown-menu class="customDropDown">
                          <treeselect v-model="initSelectedCourses" :multiple="true" :options="treeDataCourses" />
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </vs-col>
                    <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" v-if="selected_team == 'SR'"
                      class="tree-drop">
                      <vs-dropdown vs-custom-content vs-trigger-click>
                        <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                          Spoc
                          <i class="material-icons">expand_more</i>
                        </a>
                        <vs-dropdown-menu class="customDropDown">
                          <treeselect :multiple="true" :options="spocs" :value-consists-of="'LEAF_PRIORITY'"
                            value-format="object" placeholder="Select SR Spoc" v-model="selected_sr_spoc"
                            style="width: 225px;" />
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </vs-col> -->
                    <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center" v-if="selected_team != 'ExEd'"
                      class="tree-drop">
                      <vs-dropdown vs-custom-content vs-trigger-click>
                        <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                          M Levels
                          <i class="material-icons">expand_more</i>
                        </a>
                        <vs-dropdown-menu class="dropdown-login customDropDown">
                          <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </vs-col>
                    <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center" v-if="selected_team != 'ExEd'"
                      class="tree-drop">
                      <vs-dropdown vs-custom-content vs-trigger-click>
                        <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                          U Levels
                          <i class="material-icons">expand_more</i>
                        </a>
                        <vs-dropdown-menu class="dropdown-login customDropDown">
                          <treeselect v-model="initSelectedULevels" :multiple="true" openDirection="bottom" :options="treeDataULevels" />
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </vs-col>
                    <!-- focus -->
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                      <v-select v-model="selected_focus" placeholder="Focus" :options="treeDatafocus"
                        style="z-index: 13999;width:169px;height:33.69px;" class="box-bordering">
                      </v-select>
                    </vs-col>
                    <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center" v-if="selected_team == 'ExEd'"
                      class="tree-drop">
                      <vs-dropdown vs-custom-content vs-trigger-click>
                        <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                          Exed Levels
                          <i class="material-icons">expand_more</i>
                        </a>
                        <vs-dropdown-menu class="customDropDown">
                          <div>
                            <treeselect v-model="initSelectedLevelsIIML" :multiple="true" :options="treeDataLevelsIIML"
                              style="width: 225px" />
                          </div>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </vs-col>
                  </vs-row>
                  <vs-row v-if="Object.keys(this.table_data).length > 0" vs-w="12">
                    <vs-col vs-w="9" class="mt-6" vs-type="flex" vs-justify="center" vs-align="center"
                      style="padding:0px 5px 0px 10px  !important">
                      <vs-collapse type="border" class="spocs-collapse">
                        <vs-collapse-item>
                          <div slot="header">SPOCS</div>
                          <vs-col vs-w="6" class="my-4">
                            <vs-checkbox @change="selectALLCheckBoxes(), getSelectedSpocs()" v-model="all_check_boxes"
                              class="wab-checkbox">
                              <b>Select All</b>
                            </vs-checkbox>
                          </vs-col>
                          <vs-row vs-w="12" style="height:200px;overflow-y:scroll;display:flexbox">
                            <vs-col vs-w="6" style="padding:0% !important;margin:2px 0 2px 0;height:15% !important ;"
                              v-for="item in table_data" :key="item.id">
                              <vs-checkbox @change="checkALLCheckBoxes(), getSelectedSpocs()" :value="item.id"
                                :data-valuetwo="item.name" name="checkboxes" style="margin: 1%"
                                class="wab-checkbox hide-icon">
                                <b>{{ item.name }}</b>
                              </vs-checkbox>
                            </vs-col>
                          </vs-row>
                        </vs-collapse-item>
                      </vs-collapse>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="3" class="mt-6">
                      <v-select v-model="selected_tier" placeholder="SPOC Level" :options="tier"
                        style="z-index: 13998;width:168px;height:33.69px;" class="box-bordering">
                      </v-select>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col vs-w="6" vs-type="flex" vs-justify="center">
                      <div class="my-10 count-whatsapp count-whatsapp-height">
                        <vs-row>
                          <vs-col vs-w="6">
                            <p style="font-style: normal;font-weight: 600; font-size: 12px;">
                              Count : {{ total_count }}
                            </p>
                          </vs-col>
                          <vs-col vs-type="flex" vs-justify="flex-end" vs-w="6">
                            <vs-button size="small" color="#0044BA" @click="getCount()" class="get-count-btn">
                              Get Count
                            </vs-button>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col vs-w="6" vs-type="flex" vs-justify="center" class="my-10">
                      <b>Daily Limit :</b>
                      <vs-input v-model="daily_limit" class="daily-limit" />
                    </vs-col>
                  </vs-row>

                  <vs-row style="margin-top: 2%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.4" vs-w="2"
                      class="my-4">
                      <b>Search Criteria:</b>
                    </vs-col>
                    <vs-col style="overflow-y: scroll" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9"
                      class="my-4">
                      <div v-for="(chip, index) in searchCriteria" :key="index">
                        <vs-chip v-if="chip !== 'divider'" color="#0044BA">
                          <b>{{ chip }}</b>
                        </vs-chip>
                        <div v-else>
                          <div class="vl"></div>
                        </div>
                      </div>
                    </vs-col>
                    <vs-col vs-w="25" vs-type="flex" vs-justify="center" v-if="selected_spocs.length > 0"
                      class="selected-spocs-div">
                      <div class="selected-spocs">
                        <vs-row>
                          <vs-col vs-w="12">
                            <p class="ss-head">
                              Selected Spocs
                            </p>
                          </vs-col>
                          <div class="selected-spocs-scroll">
                            <vs-col vs-w="4" style="margin-bottom: 2px" v-for="(item, index) in selected_spocs"
                              :key="index">
                              <p color="#0044BA">{{ item }} </p>
                            </vs-col>
                          </div>
                        </vs-row>
                      </div>
                    </vs-col>
                  </vs-row>

                  <vs-row class="my-8">
                    <vs-col vs-type="flex" vs-justify="center" vs-w="12">
                      <vs-button color="#0044BA" icon="refresh" style="display: flex; justify-content: center"
                        @click="clearAllFeilds()">
                        <b>Refresh</b>
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </div>
                <vs-row v-if="radios1 == 'webinar'">
                  <vs-col>
                    <vs-alert :active.sync="webinarError" color="danger" closable close-icon="close">Please Select
                      Webinar
                    </vs-alert>
                  </vs-col>
                </vs-row>
                <vs-table max-items="8" search pagination :data="webinars" v-if="radios1 == 'webinar'" class="wab-table">
                  <template slot="header">
                    <h5 style="position: relative; left: 20px">Webinars</h5>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="tr">
                        <div>
                          <vs-radio color="#0044BA" v-model="selectedWebinarTopic" :vs-value="tr.id"></vs-radio>
                          <span>{{ tr.topic }} {{ tr.date }} </span>
                        </div>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-card>
            </vs-col>
            <vs-col vs-w="5">
              <vs-card style="position:relative" class="border-messages">
                <vs-row>
                  <vs-col>
                    <vs-alert :active.sync="messageError" color="danger" closable close-icon="close" ref="aler">Please
                      Select
                      Message
                    </vs-alert>
                  </vs-col>
                  <vs-col vs-w="12" vs-type="flex" vs-justify="center">
                    <hr class="horizontal-line" v-if="whatsappMessages.length > 0">
                  </vs-col>
                </vs-row>
                <hr class="horizontal-line2" v-if="whatsappMessages.length > 0">
                <vs-table color="#0044ba" search :data="whatsappMessages" class="wab-table">
                  <template slot="header">
                    <h5 style="position: relative; left: 20px">Messages</h5>
                  </template>
                  <!-- </vs-divider> -->
                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="tr.id">
                        <div style="margin-top:1.6%">
                          <vs-radio color="#0044BA" v-model="selectedWhatsappMessage" :vs-value="tr.id"></vs-radio>
                          <span @click="tooltipPopup(tr.message, tr.name)">{{ tr.name }} </span>
                        </div>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-card>
              <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-w="12">
                  <vs-pagination :total="total_pages" v-model="current_page"></vs-pagination>
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>
          <input type="hidden" id="broadcastFilters" :value="broadcastFiltersvalue" />
        </vx-card>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-offset="9" vs-w="3" class="my-4">
        <vs-button color="#0044BA" style="display: flex; justify-content: center" @click="postDetails()">
          <b>Create Broadcast</b>
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-popup class="tooltip-popup-width" style="z-index: 53010" :active.sync="popupOpen">
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <h5 class="popup-headd">{{ popup_title }}</h5>
        </vs-col>
        <vs-col vs-w="12">
          <p>{{ messages_popup_data1 }} !</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <p class="popup-inner">{{ messages_popup_data }}</p>
        </vs-col>
        <vs-col vs-w="12" class="mt-1">
          <p>Cheers Team Miles Education</p>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-slign="center" class="mt-4">
          <vs-button type="filled" size="small" @click="popupOpen = false"><b>OK</b></vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import LeadsNavBar from "../pagesComponents/LeadsNavBar.vue";
import FocusSearch from "../pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import EventBus from "../eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    LeadsNavBar,
    VTree,
    VSelectTree,
    DateRangePicker,
    FocusSearch,
    SpocSearchTree,
    Treeselect,
    "v-select": vSelect,
    VuePerfectScrollbar,
  },
  data() {
    return {
      total_pages: 1,
      current_page: 1,
      selected_spocs: [],
      popupOpen: false,
      popup_title: "",
      messages_popup_data: "",
      messages_popup_data1: "",
      get_count: "",
      webinars: [],
      selected_u_level: [],
      all_check_boxes: true,
      radios1: "marketing",
      selected_from_table: [],
      table_data: [],
      teams: [],
      spoc_with_teams: [],
      WACount: "",
      total_count: "",
      loadShow: true,
      countShow: false,
      disabledspocstree: true,
      daily_limit: 500,
      selected_team: "",
      selected_city: "",
      Spocs: [],
      selected_city_classification: "",
      cities_list: [],
      cities: [],
      city_classification: [],
      treeDatafocus: ["Delays", "Today", "Waiting", "Untapped"],
      select_role: "CM",
      messageError: false,
      webinarError: false,
      showCount: false,
      leadCount: 0,
      FilterError: false,
      whatsappMessages: [],
      selectedWhatsappMessage: "",
      selectedWebinarTopic: "",
      broadcastFiltersvalue: "",
      showingLevels: false,
      showingIIMLLevels: false,
      spocLogged: false,
      canIdWidth: "100px",
      initSelectedBDE: [],
      initSelectedBDESR: [],
      initSelectedCourses: [],
      initSelectedLevels: [],
      initSelectedLevelsIIML: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataBDESR: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      CM_courses_checkbox: [
        "CPA",
        "CMA",
        "CFA",
        "FRM",
        "USP",
        "CPA-AA",
        "CPA-US",
        "IIML-FT",
        "AICPA",
      ],
      tier: ["Con", "L1", "L2", "L3", "SL", "HF", "NHF"],
      selected_tier: "",
      selected_focus: undefined,
      exed_courses: [{
        id: "All",
        label: "All",
        children: [
          {
            id: "IIMI-BA",
            label: "IIMI-BA",
          },
          {
            id: "IIMI-AA",
            label: "IIMI-AA",
          },
          {
            id: "XLRI-HR",
            label: "XLRI-HR",
          },
          {
            id: "XLRI-SH",
            label: "XLRI-SH",
          },
          {
            id: "IIML-FT",
            label: "IIML-FT",
          },
          {
            id: "USP",
            label: "USP",
          },
        ],
      }],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA",
            },
            {
              id: "CPA-US",
              label: "CPA-US",
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "CFA-L1",
              label: "CFA-L1",
            },
            {
              id: "CFA-L2",
              label: "CFA-L2",
            },
            {
              id: "CFA-L3",
              label: "CFA-L3",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "FRM-P1",
              label: "FRM-P1",
            },
            {
              id: "FRM-P2",
              label: "FRM-P2",
            },
            {
              id: "IIMI-BA",
              label: "IIMI-BA",
            },
            {
              id: "IIMI-AA",
              label: "IIMI-AA",
            },
            {
              id: "XLRI-HR",
              label: "XLRI-HR",
            },
            {
              id: "XLRI-SH",
              label: "XLRI-SH",
            },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            {
              id: "USP",
              label: "USP",
            },
          ],
        },
      ],
      CM_courses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "USP",
              label: "USP",
            },
            {
              id: "IIML-FA",
              label: "IIML-FA",
            },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            {
              id: "IIML-SF",
              label: "IIML-SF",
            },
          ],
        },
      ],
      spocs: [],
      initSelectedULevels: [],
      treeDataULevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "U0--",
              label: "U0--",
            },
            {
              id: "U0",
              label: "U0",
            },
            {
              id: "U1",
              label: "U1",
            },
            {
              id: "U1+",
              label: "U1+",
            },
            {
              id: "U2",
              label: "U2",
            },
            {
              id: "U3",
              label: "U3",
            },
            {
              id: "U3-",
              label: "U3-",
            }, 
            {
              id: "U3+",
              label: "U3+",
            },
            {
              id: "U3++",
              label: "U3++",
            },
            {
              id: "U4",
              label: "U4",
            },
            {
              id: "U4R",
              label: "U4R",
            },
            {
              id: "U4-",
              label: "U4-",
            },
            {
              id: "U5",
              label: "U5",
            },
            {
              id: "U5+",
              label: "U5+",
            },
            {
              id: "U6",
              label: "U6",
            },
            {
              id: "U6-",
              label: "U6-",
            },
            {
              id: "U6+",
              label: "U6+",
            },
            {
              id: "U7",
              label: "U7",
            },
            {
              id: "U7-",
              label: "U7-",
            },
            {
              id: "U8",
              label: "U8",
            },
            {
              id: "U9",
              label: "U9",
            },
            {
              id: "U10",
              label: "U10",
            },

          ]
        }
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M6 AR",
                  label: "M6 AR",
                },
                {
                  id: "M6 AR-",
                  label: "M6 AR-",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M1",
                  label: "M1",
                },
              ],
            },

            {
              id: "Enrolled",
              label: "Enrolled",
              children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ],
            },
            // {
            //   id: "DND Levels",
            //   label: "DND Levels",
            //   children: [
            //     {
            //       id: "N/A",
            //       label: "N/A",
            //     },
            //     {
            //       id: "DND",
            //       label: "DND",
            //     },
            //   ],
            // },
          ],
        },
      ],
      treeDataLevelsIIML: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "Net Enquiry", label: "Net Enquiry" },
            { id: "Warm", label: "Warm" },
            { id: "Busy/NL", label: "Busy/NL" },
            { id: "Hot", label: "Hot" },
            { id: "Very Hot", label: "Very Hot" },
            { id: "Cold", label: "Cold" },
            { id: "Application Received", label: "Application Received" },
            { id: "Application Approved", label: "Application Approved" },
            { id: "Enrolled", label: "Enrolled" },
            { id: "Final payment Received", label: "Final payment Received" },
            { id: "NA", label: "NA" },
          ],
        },
      ],
      searchIn: {
        id: "",
        name: "",
      },
      rawBde: [],
      rawBdeSR: [],
      rawcities: [],
      SpocCityPrefilled: [],
      searchCriteria: [],
      count: 0,
      mwb_names: [],
      getSelectedCource: "",
      selected_sr_spoc: [],
    };
  },
  mounted() {
    this.all_check_boxes = false;
    this.getPastWebinars();
    this.getTeams();
    if (this.getrole("cm1") || this.getrole("exed2")) {
      this.disabledspocstree = true;
    } else if (this.getrole("cm4") || this.getrole("wh2")) {
      this.disabledspocstree = false;
    }
    console.log("user role", localStorage.getItem("role"));

    if (this.select_role === "CM") {
      this.treeDataCourses = this.CM_courses;
      this.showingLevels = true;
      this.showingIIMLLevels = false;
      this.getSpocAdmin();
    }
    this.getwhatsappMessages();
    this.$store.commit("CLEAR_DASHBOARD_LEADS");
    if (
      localStorage.getItem("role") === "cm4" ||
      localStorage.getItem("role") === "wc" ||
      localStorage.getItem("role") === "ac2" ||
      localStorage.getItem("role") === "fe" ||
      localStorage.getItem("role") === "cmca" ||
      localStorage.getItem("role") === "baca" ||
      localStorage.getItem("role") === "hr" ||
      localStorage.getItem("role") === "wh2"
    ) {
      this.spocLogged = false;
      this.canIdWidth = "100%";
      this.getSpocAdmin();
    } else {
      this.spocLogged = true;
      this.canIdWidth = "100px";
      this.getSpoc();
    }
  },
  watch: {
    selected_team: function (val) {
      this.all_check_boxes = true;
      this.table_data = [];
      this.selected_spocs = [];
      this.clearAllFeilds("team");
      this.sortData()
      this.getSelectedCource = "";
      this.spocs = [];
      this.initSelectedCourses = [];
      this.searchCriteria = [],
        this.hidetotal = false;
      this.total = {
        missed_calls: [],
        untracked: [],
        to_update: [],
        call_logs_today: [],
        untapped: [],
        delays: [],
        today: [],
        waiting: [],
        net_enquiries: [],
        mhps: [],
        flag: [],
      };
      this.selected_spoc = "";
      if (val != "ExEd") {
        this.treeDataCourses = this.CM_courses
        this.cities = [
          ...new Set(this.spoc_with_teams.map((item) => item.city)),
        ];
        console.log("all_cities", this.cities);
        this.courses = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "CM" ? item.sub_team : ""
            )
          ),
        ];
      } else if (val == "ExEd") {
        this.treeDataCourses = this.exed_courses
        this.courses = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "ExEd" ? item.sub_team : ""
            )
          ),
        ];

        console.log("courses", this.courses);
        this.getSelectedSpocs();
      } else if (val == "SR") {
        this.selected_spocs = [];
        let obj = {
          id: "All",
          label: "All",
          children: [],
        };
        this.spocs.push(obj);
        console.log("pushed tree obj", this.spocs);
        let sr_spocs = [];
        for (let i = 0; i < this.spoc_with_teams.length; i++) {
          const element = this.spoc_with_teams[i];
          if (element.team == "SR" && element.sub_team == "SR") {
            sr_spocs.push(element);
          }
        }
        var unique = sr_spocs
          .map((name) => {
            return {
              count: 1,
              name: name.city,
            };
          })
          .reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
          }, {});
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            var city_obj = {
              id: key,
              label: key,
              children: [],
            };
            this.spocs[0].children.push(city_obj);
          }
        }
        sr_spocs.forEach((server_spoc) => {
          this.spocs.forEach((bde) => {
            bde.children.forEach((bdechid) => {
              if (bdechid.label === server_spoc.city) {
                let name_obj = {
                  id: server_spoc.id,
                  label: server_spoc.first_name + " " + server_spoc.last_name,
                };
                this.table_data.push({
                  name: server_spoc.first_name + " " + server_spoc.last_name,
                  id: server_spoc.id,
                });
                this.selected_spocs.push(server_spoc.first_name + " " + server_spoc.last_name)
                bdechid.children.push(name_obj);
              }
            });
          });
        });
      }
      if (val != "SR") {
        this.spocs.splice("", 1);
        this.spocs.unshift("All");
      }
      this.courses.splice("", 1);
    },
    selected_city: function (val) {
      this.sortData();
      this.city_classification = [];
      this.selected_city_classification = "";
      this.getSelectedCource = "";
      let classification = ["All"];
      for (let i = 0; i < this.spoc_with_teams.length; i++) {
        if (
          val == this.spoc_with_teams[i].city &&
          this.spoc_with_teams[i].team == this.selected_team
        ) {
          if (this.spoc_with_teams[i].city_classification != null) {
            classification.push(this.spoc_with_teams[i].city_classification);
          }
        }
      }
      console.log("classification", classification);
      this.city_classification = [
        ...new Set(classification.map((item) => item)),
      ];
    },
    select_role: function (val) {
      this.initSelectedCourses = [];
      this.initSelectedBDE = [];
      if (val === "IIML") {
        this.treeDataCourses = this.IIML_courses;
        this.showingLevels = false;
        this.showingIIMLLevels = true;
        this.getSpocAdmin();
      } else if (val === "CM") {
        this.treeDataCourses = this.CM_courses;
        this.showingLevels = true;
        this.showingIIMLLevels = false;
        this.getSpocAdmin();
      }
    },
    selectedWhatsappMessage: function (val) {
      if (val !== "" || val !== undefined) {
        this.messageError = false;
      }
    },
    selectedWebinarTopic: function (val) {
      if (val !== "" || val !== undefined) {
        this.webinarError = false;
      }
    },
    select_city(val) {
      console.log("selected", val);
      this.getCityClassification(val);
      this.mergeAllSearch();
    },
    selected_city_classification: function () {
      this.getSelectedCource = "";
      this.sortData();
      this.mergeAllSearch();
    },
    selected_tier: function () {
      this.mergeAllSearch();
    },
    initSelectedBDESR: function () {
      this.mergeAllSearch();
    },
    initSelectedBDE: function () {
      this.mergeAllSearch();
    },
    selected_focus: function () {
      this.mergeAllSearch();
    },
    initSelectedCourses: function (val) {
      console.log("selected cource", val[0]);
      this.getSelectedCource = val[0];
      this.sortData();
      this.mergeAllSearch();
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    initSelectedULevels: function () {
      this.mergeAllSearch();
    },
    initSelectedLevelsIIML: function () {
      this.mergeAllSearch();
    },
    selected_sr_spoc: function () {
      console.log("this.spoc", this.spocs)
      this.mergeAllSearch();
    },
    current_page() {
      this.getwhatsappMessages();
    },
  },
  methods: {
    tooltipPopup(tr, title) {
      this.popup_title = title
      this.popupOpen = true;
      let result = tr.replace(/[*,_]/g, " ");
      let index1 = result.indexOf("!");
      let index2 = result.indexOf("Cheers")
      this.messages_popup_data = result.slice(index1 + 1, index2)
      this.messages_popup_data1 = result.slice(0, index1)
    },
    getPastWebinars() {
      axios
        .get("https://miles-whatsapp-broadcasts.2x2.ninja/api/getFutureWebinars", {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          const webinars = [];
          response.data.forEach((w) => {
            if (webinars.includes(w.topic) == false) {
              webinars.push({ 'topic': w.topic, 'id': w.id, 'date': w.start_time });
            }
          });
          this.webinars = webinars;
        });
    },
    getSelectedSpocs() {
      this.selected_spocs = []
      let spocs = [];
      var ele = document.getElementsByName("checkboxes");
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          if (ele[i].checked == true) {
            let user = ele[i].getAttribute("data-valuetwo");
            spocs.push(user)
          }
        }
      }
      this.selected_spocs = spocs
    },
    checkALLCheckBoxes() {
      let dummy = "";
      var ele = document.getElementsByName("checkboxes");
      console.log("checkboxes", ele);
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          if (ele[i].checked == false) {
            this.all_check_boxes = false;
            dummy = "false";
          }
        }
      }
      if (dummy == "") {
        this.all_check_boxes = true;
      }
    },
    selectALLCheckBoxes() {
      var ele = document.getElementsByName("checkboxes");
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = this.all_check_boxes;
      }
    },
    sortData() {
      if (this.selected_team != "ExEd") {
        if (this.selected_city !== "") {
          this.getSpocsData();
        }
      } else {
        this.getSpocsData();
      }
    },
    getSpocsData() {
      this.table_data = [];
      this.selected_spocs = []
      if (this.selected_team != "ExEd") {
        let ele = this.selected_city_classification;
        for (let i = 0; i < this.spoc_with_teams.length; i++) {
          if (this.spoc_with_teams[i].team == this.selected_team) {
            if (this.spoc_with_teams[i].city == this.selected_city) {
              if (ele == "" || ele == 'All') {
                this.table_data.push({
                  name: this.spoc_with_teams[i].first_name + ' ' + this.spoc_with_teams[i].last_name,
                  id: this.spoc_with_teams[i].id,
                });
                this.selected_spocs.push(this.spoc_with_teams[i].first_name + ' ' + this.spoc_with_teams[i].last_name)
              }
              if (
                this.spoc_with_teams[i].city_classification == ele &&
                ele !== ""
              ) {
                this.table_data.push({
                  name: this.spoc_with_teams[i].first_name + ' ' + this.spoc_with_teams[i].last_name,
                  id: this.spoc_with_teams[i].id,
                });
                this.selected_spocs.push(this.spoc_with_teams[i].first_name + ' ' + this.spoc_with_teams[i].last_name)
              }
            }
          }
        }
      } else {
        for (let i = 0; i < this.spoc_with_teams.length; i++) {
          if (this.spoc_with_teams[i].team == this.selected_team) {
            this.table_data.push({
              name: this.spoc_with_teams[i].first_name + ' ' + this.spoc_with_teams[i].last_name,
              id: this.spoc_with_teams[i].id,
            });
            this.selected_spocs.push(this.spoc_with_teams[i].first_name + ' ' + this.spoc_with_teams[i].last_name)
          }
        }
      }
    },
    getTeams() {
      let url = `${constants.SERVER_API}getAllActiveSpocsForDashbaord`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getAllSpocWithTeams", response);
          this.spoc_with_teams = response.data;
          this.teams = [
            ...new Set(
              this.spoc_with_teams.map((item) => {
                if (item.team !== "GM") {
                  return item.team;
                }
              })
            ),
          ];
          this.teams = this.teams.filter((item) => item !== undefined);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async search_API(page, copyfor) {
      let focus = this.selected_focus;
      if (this.selected_focus !== null && this.selected_focus !== undefined) {
        focus = this.selected_focus.toLowerCase();
      }
      let levels = [];
      // let u_levels = [];
      let bde = [];
      let courses = [];
      let iimllevels = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr" ||
        localStorage.getItem("role") === "wh2"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevelsIIML.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevelsIIML[0].children.forEach((child) => {
            iimllevels.push(child.label);
          });
        } else {
          iimllevels.push(sort);
        }
      });
      // this.initSelectedULevels.forEach((sort) => {
      //   if (sort === "All") {
      //     this.treeDataLevels[0].children[0].children.forEach((child) => {
      //       u_levels.push(child.label);
      //     });
      //   } 
      //   else {
      //     u_levels.push(sort);
      //   }
      // });
      this.initSelectedLevels.forEach((sort) => {
        console.log(sort)
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "Enrolled") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            levels.push(child.label);
          });
        }
        // else if (sort === "DND Levels") {
        //   this.treeDataLevels[0].children[3].children.forEach((child) => {
        //     levels.push(child.label);
        //   });
        // } 
        else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        // u_levels:u_levels.join(),
        focus: focus,
        spoc_ids: bdeid.join(),
        iiml_levels: iimllevels.join(),
      };
      if (copyfor === true) {
        console.log("Is this even running? Yes it is.");
        console.log("obj in if", obj);
      } else {
        obj.whatsappMessage = this.selectedWhatsappMessage;
        obj.whats_app_message_id = this.selectedWhatsappMessage;
        obj.webinarMessage = this.selectedWebinarTopic;
        obj.webinar_message_id = this.selectedWebinarTopic;
        if (obj.whatsappMessage === "") {
          this.messageError = false;
        } else {
          this.messageError = false;
          console.log("obj in else", obj);
          this.broadcastFiltersvalue = JSON.stringify(obj);
          console.log("JSON.stringify(obj)", this.broadcastFiltersvalue);
          // axios
          //   .post(
          //     `${constants.SERVER_API}getSearchedMwbLeads?page=${page}`,
          //     obj,
          //     {
          //       headers: {
          //         Authorization: `Bearer ${localStorage.userAccessToken}`,
          //       },
          //     }
          //   )
          //   .then((response) => {
          //     // console.log("laedas data", response);
          //     this.countsleads = response.data.total;
          //     response.data.data.forEach((lead) => {
          //       lead.engagements = [];
          //       lead.mobile = [];
          //       lead.emails = [];
          //     });
          //     this.currentTablePage = response.data.current_page;
          //     this.tablelinks = response.data.last_page;
          //     this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          //   })
          //   .catch((error) => {
          //     this.handleError(error);
          //   });
        }
      }
    },
    getSpoc() {
      this.initSelectedBDE = [];
      let url = ``;
      if (this.select_role === "CM") {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (this.select_role === "IIML") {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("spocs", response.data);
          this.rawBde = [];
          let usercity = "";
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          this.SpocCityPrefilled = [];
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.SpocCityPrefilled.push(key.charAt(0).toUpperCase());
            }
          }
          response.data.spocs.forEach((spoc) => {
            if (this.getrole("cm1")) {
              if (spoc.city === usercity) {
                this.rawBde.push(spoc);
              }
            } else if (this.getrole("exed") || this.getrole("exed2")) {
              this.rawBde.push(spoc);
            }
          });
          this.treeDataBDE[0].children = [];
          this.rawBde.forEach((data) => {
            var obj = {
              id: data.full_name,
              label: data.full_name,
            };
            this.treeDataBDE[0].children.push(obj);
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "exed" ||
            localStorage.getItem("role") === "exed2"
          ) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.spocs = response.data.spocs;
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }

          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });

          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.Spocs = response.data.spocs;
          this.getCityList(response.data.spocs);
          this.getSRSpoc();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCityList(spocs_data) {
      let city_list = [...new Set(spocs_data.map((item) => item.city))];
      this.cities_list = city_list.sort();
    },
    getCityClassification(city_select) {
      var scity = [];
      for (let i = 0; i < this.Spocs.length; i++) {
        const element = this.Spocs[i];
        if (element.city == city_select) {
          scity.push(element);
        }
      }
      let city_list = [
        ...new Set(scity.map((item) => item.city_classification)),
      ];
      this.city_classification = city_list.sort();
    },

    getSRSpoc() {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBdeSR = response.data.spocs;
          this.treeDataBDESR[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDESR[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDESR.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.treeDataBDESR[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDESR[0].children
          );
          if (this.rawBdeSR.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });



        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {

          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria = this.searchCriteria.reduce((unique, o) => {
              if (!unique.some((sort) => sort.name === o.name)) {
                unique.push(o);
              }
              return unique;
            }, []);
            console.log(this.searchCriteria)
            this.searchCriteria.push(sort);

          }
        });
      }
      this.searchCriteria.push("divider");
      if (this.initSelectedCourses !== null) {
        this.initSelectedCourses.forEach((sort) => {
          if (sort === "All") {
            console.log("cort", sort, this.treeDataCourses, this.spocs)
            this.treeDataCourses[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      this.searchCriteria.push("divider");
      this.selected_u_level = []
      this.initSelectedULevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataULevels[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
            this.selected_u_level.push(child.label);
          });
        }
        else {
          this.searchCriteria.push(sort);
          this.selected_u_level.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "Enrolled") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        }
        //  else if (sort === "DND Levels") {
        //   this.treeDataLevels[0].children[3].children.forEach((child) => {
        //     this.searchCriteria.push(child.label);
        //   });
        // }
        else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.selected_focus !== null && this.selected_focus !== undefined) {
        this.searchCriteria.push(this.selected_focus);
      }

      this.searchCriteria.push("divider");
      this.initSelectedLevelsIIML.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevelsIIML[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.selected_sr_spoc !== null) {
        this.selected_sr_spoc.forEach((sort) => {
          this.searchCriteria.push(sort.label);
        })
      }
    },
    search() {
      this.search_API();
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    getwhatsappMessages() {
      this.$vs.loading();
      //http://milescmback.2x2.ninja/api/whatsAppBroadcast
      let url = `${constants.SERVER_API}WhatsappBroadcastMessage?page=`+ this.current_page;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("whatsapp messages", response.data);
          this.whatsappMessages = response.data.data;
          this.total_pages = response.data.last_page;
          this.current_page = response.data.current_page;
          this.$vs.loading.close();
        });
    },
    postDetails() {
      if (this.daily_limit == 0 || this.daily_limit == '') {
        this.$vs.notify({
          title: "Error",
          text: "Please keep minimum 500 as the daily limit",
          color: "danger",
        });
      } else {
        if (this.selectedWhatsappMessage == '') {
          this.messageError = true;
        }
        if (this.selectedWebinarTopic == '') {
          this.webinarError = true;
        }
        let spocs = [];
        var ele = document.getElementsByName("checkboxes");
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type == "checkbox") {
            if (ele[i].checked == true) {
              spocs.push(ele[i].value);
            }
          }
        }
        spocs = spocs.toString();
        let courses = this.initSelectedCourses.toString();
        let str_levels = this.initSelectedLevels.toString();
        // let str_exed = this.initSelectedLevelsIIML.toString();
        let obj = {
          spoc_ids: spocs,
          courses: courses,
          levels: str_levels,
          city: this.selected_city,
          city_classification: this.selected_city_classification,
          tier: this.selected_tier,
          focus: this.selected_focus,
          team: this.selected_team,
          wab_type: this.radios1,
          whatsapp_message_id: this.selectedWhatsappMessage,
          daily_limit: this.daily_limit,
          webinar_id: this.selectedWebinarTopic,
        };
        if (this.selected_team == 'ExEd') {
          let str_exed = this.initSelectedLevelsIIML.toString();
          obj.iiml_levels = str_exed;
        } else {
          obj.iiml_levels = this.selected_u_level.toString();
        }
        console.log("create broadcast", obj);
        let hitApi = false;
        if (this.radios1 == 'marketing') {
          if (this.messageError == false) {
            hitApi = true
          }
        } else if (this.webinarError == false && this.messageError == false) {
          hitApi = true
        }
        if (hitApi) {
          this.$vs.loading();
          axios
            .post(
              `https://miles-whatsapp-broadcasts.2x2.ninja/api/createBroadcast`,
              obj,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              }
            )
            .then((response) => {
              console.log("WACount", response);
              if (response.data.status === "success") {
                this.$vs.notify({
                  title: "Success",
                  text: response.data.message,
                  color: "success",
                });
                this.WACount = response.data.count;
                this.$emit("refresh-table");
              } else if (response.data.status === "error") {
                this.$vs.notify({
                  title: "Error",
                  text: response.data.message,
                  color: "danger",
                });
                this.WACount = response.data.count;
              }
              this.clearAllFeilds()
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.handleError(error);
              this.clearAllFeilds()
              this.$vs.loading.close();
            });
        }
      }
    },
    getCount() {
      let spoc_id = [];
      var ele = document.getElementsByName("checkboxes");
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          if (ele[i].checked == true) {
            spoc_id.push(ele[i].value);
          }
        }
      }
      let str_spoc_id = spoc_id.toString();
      let courses = this.initSelectedCourses.toString();
      let levels = this.initSelectedLevels.toString();

      let obj = {
        spoc_ids: str_spoc_id,
        courses: courses,
        levels: levels,
        city: this.selected_city,
        city_classification: this.selected_city_classification,
        tier: this.selected_tier,
        focus: this.selected_focus,
        team: this.selected_team,
      };
      if (this.selected_team == 'ExEd') {
          let string_ExEd = this.initSelectedLevelsIIML.toString();
          obj.iiml_levels = string_ExEd;
        } else {
          obj.iiml_levels = this.selected_u_level.toString();
        }
      console.log("get count", obj);
      // if (this.selected_city == "" ) {
      //     this.$vs.notify({
      //     title: "Error",
      //     text: "Please select at least one spoc",
      //     color: "danger",
      //   });
      // }
      // else 
      if (str_spoc_id == "") {
        this.$vs.notify({
          title: "Error",
          text: "There are no spocs for this selected city",
          color: "danger",
        });
        this.total_count = 0
      } else {
        axios
          .get(`https://miles-whatsapp-broadcasts.2x2.ninja/api/getTotalCount`, {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("total count", response.data);
            this.total_count = response.data;
            // if (response.data.status === "success") {
            //   this.$vs.notify({
            //     title: "Success",
            //     text: response.data.message,
            //     color: "success",
            //   });
            //   this.total_count = response.data.count;
            // }
            // if (response.data.status === "error") {
            //   this.$vs.notify({
            //     title: "Error",
            //     text: response.data.message,
            //     color: "danger",
            //   });
            //   this.total_count = response.data.count;
            // }
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    clearAllFeilds(val) {
      if (val != 'team') {
        this.selected_team = "";
      }
      this.total_count = null;
      this.daily_limit = 500;
      this.selected_focus = undefined;
      this.table_data = [];
      this.selected_spocs = [];
      this.selected_city = "";
      this.getSelectedCource = "";
      this.selected_city_classification = "";
      this.selected_tier = "";
      this.selected_sr_spoc = [];
      this.initSelectedLevels = [];
      this.initSelectedULevels = [];
      this.initSelectedLevelsIIML = [];
      this.searchCriteria.push('divider')
    },
  },
};
</script>

<style lang="scss" >
@import "@/assets/scss/vuesax/pages/dropdown.scss";

.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}

.gap {
  margin-bottom: 4%;
}

.inicolor {
  color: gray;
}

.cardtext {
  color: gray;
  font-size: 20px;
}

.whatsapp-broadcast-header {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccccdd;
  overflow: auto;
  border-radius: 15px;
  background-color: white;
}

.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 200px;
}

.vs-dropdown--menu {
  width: 200px !important;
}

.gap {
  margin-right: 1%;
}

.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-iconn {
  outline: none;
  text-decoration: none !important;
}

i {
  font-size: 18px;
}

.vl {
  border-left: 1px solid black;
  height: 35px;
}

#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar {
  overflow-y: scroll;
}

.buttonrow {
  margin: 5px;
  justify-content: flex-end;
}

.buttonlist {
  width: 200px;
}

.con-vs-dropdown--menu {
  z-index: 500000;
}

.tree-drop {
  margin-top: 0.8%;
}

.tree-drop>.vs-con-dropdown {
  width: 168px !important;
  border: 1px solid #0044BA;
  border-radius: 5px;
  padding: 5.8px;
  color: #000000;
  display: flex;
  justify-content: center;
}


.a-iconn {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.a-iconn>.material-icons {
  margin-left: auto;
}

.table-data {
  width: 50%;
  float: left;
  margin: 1% 0 1% 0 !important;
}

.radios {
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 9px;
}

.card-inside-popup {
  background-color: #0044ba;
  border-radius: 15px;
}

.count-whatsapp {
  width: 360px;
  border: 1px solid #0044ba;
  border-radius: 5px;
}

.count-whatsapp-height {
  display: flex;
  align-items: center;
  height: 37.69px;
}

.daily-limit {
  width: 360px
}

.spocs-collapse {
  width: 97%;
}

.spocs-collapse.border {
  border-color: #0044BA !important;
}

.horizontal-line2 {
  position: absolute;
  width: 90%;
  border-radius: 1px;
  color: #0044BA;
  margin-left: 4%;
  margin-top: 500px
}

.selected-spocs {
  width: 100%;
  border: 1px solid #0044ba;
  border-radius: 5px;
  box-shadow: 4px 8px #0044ba3b;
}

.selected-spocs-div {
  padding: 0 3% 0 3%
}

@media screen and (min-width: 1450px) {
  .horizontal-line2 {
    margin-top: 495px
  }
}

@media screen and (min-width: 1560px) {
  .spocs-collapse {
    width: 97%;
  }

  .count-whatsapp {
    width: 385px;
  }

  .daily-limit {
    width: 385px
  }

  .horizontal-line2 {
    margin-top: 500px
  }
}

@media screen and (min-width: 1700px) {
  .spocs-collapse {
    width: 96.2%;
  }

  // .horizontal-line2 {
  //   margin-top: 490px
  // }
  .selected-spocs-div {
    padding: 0 3.5% 0 3.5%
  }
}

@media screen and (min-width: 1760px) {
  .count-whatsapp {
    width: 400px;
  }

  .daily-limit {
    width: 400px
  }

  .spocs-collapse {
    width: 95%;
  }
}

@media screen and (min-width: 1845px) {
  .count-whatsapp {
    width: 415px;
  }

  .daily-limit {
    width: 415px
  }

  .spocs-collapse {
    width: 92.5%;
  }

  .horizontal-line2 {
    margin-top: 510px !important
  }

  .selected-spocs-div {
    padding: 0 4% 0 4%
  }
}

.spoc-dropdown-wab .vs-dropdown--custom {
  margin-left: 80%
}

.spoc-dropdown-wab .vs-dropdown--menu {
  width: 275px !important
}

.border-messages .vs-con-table .vs-con-tbody {
  border: 0px !important;
}

.border-messages .vs-con-table .vs-table--pagination {
  margin-top: 3rem !important;
}

.horizontal-line {
  position: absolute;
  width: 90%;
  border-radius: 1px;
  color: #0044BA;
  margin-left: 1%;
  margin-top: 60px
}

.opopop .vs__dropdown-toggle .vs__search {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}

.box-bordering .vs__dropdown-toggle {
  border: 1px solid #0044BA !important;

}

.box-bordering .vs__dropdown-toggle .vs__search,
.box-bordering .vs__dropdown-toggle .vs__selected {
  color: #000;
  font-weight: 400;
  font-size: 12px;
}

.tooltip-popup-width .vs-popup {
  width: 500px;
  height: auto;
}

.tooltip-popup-width .vs-popup--header {
  display: none;
}

.popup-inner {
  text-align: justify;
  text-justify: inter-word;
  max-height: 425px;
  overflow-y: scroll;
  scrollbar-width: thin;
  -ms-overflow-style: thin;
}

.popup-headd {
  text-align: center;
}

@media screen and (max-height: 670px) {

  .vue-treeselect__menu {
    max-height: 200px !important;
  }
}

.wab-table .vs-table--header .vs-table--search .vs-table--search-input {
  border-color: #0044ba;
}

.daily-limit .vs-inputx {
  border-color: rgba(0, 68, 186) !important;
}

.get-count-btn.small {
  padding: 0px !important;
  font-size: 8px !important;
  width: 60px;
  font-weight: 900;
  height: 18px;
}

.open-item>.vs-collapse-item--header {
  background-color: #0044BA;
  border-radius: 5px;
  color: #FFFFFF;
}

.spocs-collapse>.vs-collapse-item>.vs-collapse-item--header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.wab-checkbox .vs-checkbox {
  border-radius: 10px;
}

.wab-checkbox input:checked+.vs-checkbox {
  border: 0px !important;
  border-radius: 10px;
}

.hide-icon .vs-checkbox--input:checked+.vs-checkbox .vs-icon {
  display: none;
}

.wab-checkbox .vs-checkbox--check {
  background: #0044BA;
}

.wab-checkbox .vs-checkbox--input:checked+.vs-checkbox .vs-icon {
  margin-top: 3px;
}

.wab-card-height {
  min-height: 610px;
  // height:auto
}

.border-messages {
  min-height: 580px;
}

.selected-spocs-scroll {
  display: contents;
  height: 200px;
  overflow-y: scroll;
}

.ss-head {
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  text-align: center;
  font-family: 'Montserrat'
}

// .vs-pagination--li.is-current .effect{
//   background-color: #0044BA !important;
// }</style>